import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Because Transparency is a Necessity',
    paragraph: ' Information is archived and accessible at any time. With few clicks, you can just launch a new consultation, select the suppliers, compare their offers and start negotiating. Track your purchases and savings, get the best products out of your suppliers and make the right decisions. You can also audit the whole process in one click.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="center-content" >
            <div className="container-xs">
            <h2 className={
                classNames(
                  'mt-0',
                  sectionHeader.paragraph ? 'mb-16' : 'mb-0'
                )}>
                  Because <span className="text-color-secondary">Transparency </span> 
                  <br/>is a <span className="text-color-secondary">Necessity</span>
            </h2>
            <p className="m-0">{sectionHeader.paragraph}</p>
            </div>
            <br />
          </div>

          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0" style={{color: "aliceblue"}}>
                    We have more than 50.000 consultations per year, an averge of 30 consultations per day, it should be quick. No time to go over an ERP, clicking on many screens, heavy internal processes, especially for small amounts.
                    This is the solution we were looking for.
                    </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-primary"> Day to Day</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a style={{color: "aliceblue"}} href="#0">Procurement Manager</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0" style={{color: "aliceblue"}}>
                  We can have now a clear picture about our purchases, we can see the savings and the suppliers that are not performing and make the right decisions.  
                  Over the 50.000 yearly consultations, if we save just 1 EUR per consultation, it's a 50.000 EUR savings per year. Amazing !

                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-primary">Business Tracker</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a style={{color: "aliceblue"}} href="#0">CFO</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0" style={{color: "aliceblue"}}>
                    We need to trace all the interactions between the procurements team and the suppliers. We need to have a clear tracing of the negotiations, the offers and the decisions.
                    All these information are needed to be synchronized with an ERP seamlessly.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-primary">Security and Integration</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a style={{color: "aliceblue"}} href="#0">CTO</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;